<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :width="progress ? '200px':'650px'"
      @click:outside="close()"
      @keydown.esc="close()"
      scrollable
    >
    <v-card
        color="primary"
        dark
        v-if="progress"
        width="150px"
    >
      <v-card-text>
        Please stand by
      <v-progress-linear
      width="150px"
      indeterminate
      color="white"
      class="mb-0"
      ></v-progress-linear>
      </v-card-text>
    </v-card>

      <v-card
      tile
      v-else
      >
        <v-card-title
          class="custom-modal-title"
          primary-title
        >
          Select Item
          <v-spacer></v-spacer>
          <span @click="close" style="cursor:pointer">x</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"

            single-line
            hide-details
            ></v-text-field>
            <v-list
              subheader
              two-line
            >
              <v-list-item
                v-for="(folder,index) in search ? resultQuery : cart_data"
                :key="index"
              >
                <v-list-item-avatar>
                  <v-checkbox
                    v-model="selected"
                    :key="folder.id"
                    :value="folder.id"
                    @change="checkhed_data(folder.id)"
                  ></v-checkbox>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="custom-list-item-title-modal" v-text="folder.name"></v-list-item-title>
                  <v-list-item-subtitle class="custom-list-item-subtitle-modal" > Rp. {{folder.base_price | numeral('0,0')}}</v-list-item-subtitle>

                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon small rounded depressed @click="removeCart(folder.id)" :disabled="folder.pesan <= 1 || !folder.checkhed">
			              		<v-icon dark color="error">remove_circle</v-icon>
			            </v-btn>

                </v-list-item-action>
                <v-list-item-action class="text-center">
			            <input type="number" @keypress="isNumber($event)" min="1" v-model="folder.pesan" style="width:60px !important; border-bottom:1px solid black" text-xs-center :disabled="!folder.checkhed">
                </v-list-item-action>
                <v-list-item-action style="margin-left:0px !important">
			              	<v-btn icon small rounded depressed @click="addCart(folder.id)" :disabled="!folder.checkhed">
			              		<v-icon dark color="success">add_circle</v-icon>
			              	</v-btn>
                </v-list-item-action>
              </v-list-item>

            </v-list>

          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            tile
            outlined
            class="custom-modal-title"
            @click="close"
          >
            Cancel
          </v-btn>

           <v-btn
            color="success"
            tile
            outlined
            :disabled="!valid"
            @click="save_cart()"
            :loading="loading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GlobalMixin from '../../../mixin/index'

export default {
  props: ['user_edit'],
  mixins:[GlobalMixin],

  methods: {
    ...mapActions({
      setSnakbar: 'snakbar/setSnakbar',
      setCart:'cart/setCart'
    }),

    close () {
      this.$emit('close')
    },
    removeCart(id) {
      let cart_selected = this.cart_data.find(element => element.id === id)
      let index = this.cart_data.findIndex(x => x.id === id)

      if (cart_selected.pesan > 1){
         cart_selected.pesan--
        this.cart_data.splice(index,1,cart_selected)
      }
    },

    addCart(id) {
      let cart_selected = this.cart_data.find(element => element.id === id)
      let index = this.cart_data.findIndex(x => x.id === id)
      cart_selected.pesan++
      this.cart_data.splice(index,1,cart_selected)
    },
    async get_data_master (table,param) {
      await this.axios.get(`/v1/${table}/all?company_id=` + this.user.company_id + '&' + param, {
        headers: {
          Authorization: 'Bearer ' + this.user.token
        }
      })
        .then(async (ress) => {
          await ress.data.data.forEach(element => {
            this.cart_data.push({
            ...element,
            checkhed:false,
            pesan:1})
          });
          await this.cart_sesion.forEach(x => {
            let index = this.cart_data.findIndex(element=>element.id == x.id)
            this.selected.push(x.id)
            x.pesan = x.qty
            this.cart_data.splice(index,1,x)
          })

        })
        .catch(err => console.log(err))
        this.progress = false
    },
    async save_cart() {
      let cart = await this.cart_data.filter(x => x.checkhed === true)
      cart = await cart.map(x => {
        var data = {...x,qty:x.pesan}
        return data
      })
      this.setCart(cart)
      this.close()
    },
    checkhed_data(id) {
      let cart_selected = this.cart_data.find(element => element.id === id)
      let index = this.cart_data.findIndex(x => x.id === id)
      cart_selected.checkhed = !cart_selected.checkhed
      this.cart_data.splice(index,1,cart_selected)
    }

  },

  watch: {
  },

  data: () => ({
    valid: true,
    lazy: false,
    loading: false,
    dialog:true,
    progress:true,
    cart_data:[],
    selected:[],
    search:'',
  }),

  computed: {
    ...mapGetters({
      color: 'layout/color',
      user: 'auth/user',
      cart_sesion:'cart/cart'
    }),
    backgroundColor () {
      return `background-color:${this.color} !important`
    },
    resultQuery(){
      if(this.search){
        return this.cart_data.filter((item)=>{
        return this.search.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
      })
      }else{
        return this.cart_data;
      }
    }

  },
  filters:{

  },

  async created () {
    await this.get_data_master('product','is_stock=Y')

  }
}
</script>
